import {
  AssignmentIndOutlined,
  CalendarMonth,
  GppGoodOutlined,
  Groups3Outlined,
  KeyboardDoubleArrowLeft,
  KeyboardDoubleArrowRight,
  LocalHospitalOutlined,
  LogoutOutlined,
  MonetizationOnOutlined,
  PendingActionsOutlined,
  PlaceOutlined,
  PortraitOutlined,
  QuestionAnswerOutlined,
  SearchOutlined,
  ThreePOutlined,
  VerifiedUserOutlined,
} from "@mui/icons-material";
import { Box, Drawer, Typography } from "@mui/material";
import React, { useContext } from "react";
import { Menu, MenuItem, Sidebar } from "react-pro-sidebar";
import { useNavigate } from "react-router-dom";
import { customColors } from "../../assets/styles/Theme";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";

const SidebarComponent = () => {
  const navigate = useNavigate();

  const { userData } = useContext(AuthContext);
  const {
    activeSidebarText,
    openDrawer,
    setOpenDrawer,
    collapsed,
    setCollapsed,
  } = useContext(ActiveSidebarContext);

  return (
    <>
      <Box
        sx={{
          backgroundColor: customColors.color_primary,
          display: { xs: "none", md: "block" },
          borderTop: `3px solid ${customColors.color_white}`,
        }}
      >
        <Sidebar
          collapsed={collapsed}
          backgroundColor={customColors.color_primary}
          rootStyles={{ border: "0rem", minHeight: "100vh" }}
        >
          <Menu>
            {collapsed ? (
              <MenuItem
                rootStyles={{
                  color:
                    activeSidebarText === "dashboard"
                      ? customColors.color_secondary
                      : customColors.color_white,
                  backgroundColor:
                    activeSidebarText === "dashboard"
                      ? customColors.color_light_gray
                      : "",
                  textAlign: "left",
                  ":hover": { color: customColors.color_secondary },
                }}
                icon={<KeyboardDoubleArrowRight />}
                onClick={() => {
                  setCollapsed(!collapsed);
                  navigate("/dashboard");
                }}
                active={activeSidebarText === "dashboard"}
              ></MenuItem>
            ) : (
              <MenuItem
                rootStyles={{
                  color:
                    activeSidebarText === "dashboard"
                      ? customColors.color_secondary
                      : customColors.color_white,
                  backgroundColor:
                    activeSidebarText === "dashboard"
                      ? customColors.color_light_gray
                      : "",
                  textAlign: "left",
                  ":hover": { color: customColors.color_secondary },
                }}
                suffix={<KeyboardDoubleArrowLeft />}
                onClick={() => {
                  setCollapsed(!collapsed);
                  navigate("/dashboard");
                }}
                active={activeSidebarText === "dashboard"}
              >
                <Typography variant="h6">Dashboard</Typography>
              </MenuItem>
            )}
          </Menu>
          {(userData?.role === "doctor" ||
            userData?.role === "nurse" ||
            userData?.role === "patient") && <UserSidebar />}

          {userData?.role === "admin" && <AdminSidebar />}
          {userData?.role === "hospital" && <HospitalSidebar />}
        </Sidebar>
      </Box>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(!openDrawer)}>
        <Sidebar
          collapsed={collapsed}
          backgroundColor="#212f45"
          rootStyles={{ border: "0rem", minHeight: "100vh" }}
        >
          <Menu>
            {collapsed ? (
              <MenuItem
                rootStyles={{
                  color: "white",
                  backgroundColor:
                    activeSidebarText === "dashboard" ? "#364e76" : "",
                  textAlign: "left",
                  ":hover": { color: "black" },
                }}
                icon={<KeyboardDoubleArrowRight />}
                onClick={() => {
                  setCollapsed(!collapsed);
                  navigate("/");
                }}
                active={activeSidebarText === "dashboard"}
              ></MenuItem>
            ) : (
              <MenuItem
                rootStyles={{
                  color: "white",
                  backgroundColor:
                    activeSidebarText === "dashboard" ? "#364e76" : "",
                  textAlign: "left",
                  ":hover": { color: "black" },
                }}
                suffix={<KeyboardDoubleArrowLeft />}
                onClick={() => {
                  setCollapsed(!collapsed);
                  navigate("/");
                }}
                active={activeSidebarText === "dashboard"}
              >
                <Typography variant="h6">Dashboard</Typography>
              </MenuItem>
            )}
            <hr />
            {/* <Item
              title="My Profile"
              to="/profile"
              icon={<PortraitOutlined />}
              isActive={sidebarActiveText === "profile"}
            /> */}
          </Menu>
          <Menu>
            <MenuItem
              icon={<LogoutOutlined />}
              rootStyles={{
                color: "white",
                textAlign: "left",
                ":hover": { color: "black" },
              }}
              className="sidebar-item"
              //   onClick={handleLogout}
            >
              Logout
            </MenuItem>
          </Menu>
        </Sidebar>
      </Drawer>
    </>
  );
};

const Item = ({ title, to, icon, isActive }) => {
  const navigate = useNavigate();
  return (
    <MenuItem
      icon={icon}
      rootStyles={{
        color: isActive
          ? customColors.color_secondary
          : customColors.color_white,
        backgroundColor: isActive ? customColors.color_light_gray : "",
        textAlign: "left",
        ":hover": { color: customColors.color_secondary },
      }}
      className="sidebar-item"
      onClick={() => navigate(to)}
      active={isActive}
    >
      {title}
    </MenuItem>
  );
};

const UserSidebar = () => {
  const { userData } = useContext(AuthContext);
  const { activeSidebarText } = useContext(ActiveSidebarContext);
  return (
    <Menu>
      <Item
        title="Search"
        to="/search"
        icon={<SearchOutlined />}
        isActive={activeSidebarText === "search"}
      />
      <Item
        title="Find"
        to="/map"
        icon={<PlaceOutlined />}
        isActive={activeSidebarText === "map"}
      />
      <Item
        title="Appointments"
        to="/appointments"
        icon={<CalendarMonth />}
        isActive={activeSidebarText === "appointments"}
      />
      {(userData?.role === "doctor" || userData?.role === "nurse") && (
        <Item
          title="Chats"
          to="/chat-room"
          icon={<QuestionAnswerOutlined />}
          isActive={activeSidebarText === "chat-room"}
        />
      )}
      <Item
        title="My Profile"
        to="/user"
        icon={<PortraitOutlined />}
        isActive={activeSidebarText === "profile"}
      />
    </Menu>
  );
};

const AdminSidebar = () => {
  const { activeSidebarText } = useContext(ActiveSidebarContext);
  return (
    <Menu>
      <Item
        title="Add Hospitals"
        to="/admin/hospitals"
        icon={<LocalHospitalOutlined />}
        isActive={activeSidebarText === "add-hospitals"}
      />
      <Item
        title="Add Admins"
        to="/admin/admins"
        icon={<VerifiedUserOutlined />}
        isActive={activeSidebarText === "add-admins"}
      />
      <Item
        title="Doctors"
        to="/all-doctors"
        icon={<ThreePOutlined />}
        isActive={activeSidebarText === "all-doctors"}
      />
      <Item
        title="Nurses"
        to="/all-nurses"
        icon={<AssignmentIndOutlined />}
        isActive={activeSidebarText === "all-nurses"}
      />
      <Item
        title="Patients"
        to="/all-patients"
        icon={<Groups3Outlined />}
        isActive={activeSidebarText === "all-patients"}
      />
      <Item
        title="Payments"
        to="/all-payments"
        icon={<MonetizationOnOutlined />}
        isActive={activeSidebarText === "all-payments"}
      />
      <Item
        title="Find"
        to="/map"
        icon={<PlaceOutlined />}
        isActive={activeSidebarText === "map"}
      />
    </Menu>
  );
};

const HospitalSidebar = () => {
  const { userData } = useContext(AuthContext);
  const { activeSidebarText } = useContext(ActiveSidebarContext);
  return (
    <Menu>
      <Item
        title="Doctors"
        to="/all-doctors"
        icon={<ThreePOutlined />}
        isActive={activeSidebarText === "all-doctors"}
      />
      <Item
        title="Nurses"
        to="/all-nurses"
        icon={<AssignmentIndOutlined />}
        isActive={activeSidebarText === "all-nurses"}
      />
      <Item
        title="Patients"
        to="/all-patients"
        icon={<Groups3Outlined />}
        isActive={activeSidebarText === "all-patients"}
      />
      <Item
        title="Accepted Users"
        to={`/${userData.uid}/accepted`}
        icon={<GppGoodOutlined />}
        isActive={activeSidebarText === "hospital-all-accepted"}
      />
      <Item
        title="Pending Users"
        to={`/${userData.uid}/pending`}
        icon={<PendingActionsOutlined />}
        isActive={activeSidebarText === "hospital-all-pending"}
      />
      <Item
        title="Find"
        to="/map"
        icon={<PlaceOutlined />}
        isActive={activeSidebarText === "map"}
      />
    </Menu>
  );
};

export default SidebarComponent;
