import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const PaymentStatusPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = queryParams.get("st"); // Get the 't' parameter (e.g., 'success' or 'failure')

  // Determine the message and styling based on the status
  const isSuccess = status === "success";
  const message = isSuccess
    ? "Payment Successful! Thank you for your purchase."
    : "Payment Failed. Please try again or contact support.";
  const icon = isSuccess ? (
    <CheckCircleOutlineIcon style={{ fontSize: 100, color: "green" }} />
  ) : (
    <ErrorOutlineIcon style={{ fontSize: 100, color: "red" }} />
  );

  const handleRedirect = () => {
    navigate("/dashboard");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      bgcolor={isSuccess ? "#e8f5e9" : "#ffebee"}
      textAlign="center"
      padding="2rem"
    >
      {icon}
      <Typography
        variant="h4"
        sx={{ marginY: "1rem", color: isSuccess ? "green" : "red" }}
      >
        {isSuccess ? "Payment Successful" : "Payment Failed"}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "1.5rem" }}>
        {message}
      </Typography>
      <Button
        variant="contained"
        color={isSuccess ? "success" : "error"}
        onClick={handleRedirect}
      >
        Go to Home
      </Button>
    </Box>
  );
};

export default PaymentStatusPage;
