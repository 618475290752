import { Box, Modal, Paper, Typography } from "@mui/material";
import { blue, green, red } from "@mui/material/colors";
import { LoadingButton } from "@mui/lab";

const CustomModal = ({
  data,
  open,
  onClose,
  showButton,
  btnText,
  onClickFunc,
  loading,
  showActivateButton,
  activateLoading,
  onActivateButtonClick,
  activateBtnText,
}) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Paper
        sx={{
          width: "50%",
          padding: 4,
          outline: "none",
          margin: "auto",
          marginTop: { md: "10%", xs: "2vh" },
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {data.map((item, index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: 1,
                marginTop: "0.3rem",
              }}
            >
              <Typography>{item.key1}: </Typography>
              <Typography sx={{ fontWeight: "bold" }}>{item.value1}</Typography>
            </Box>
          ))}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <LoadingButton
              variant="contained"
              sx={{
                color: "primary.contrastText",
                backgroundColor: green[800],
                "&:hover": { backgroundColor: green[900] },
                width: "20%",
                marginTop: "2rem",
              }}
              onClick={onClose}
            >
              Close
            </LoadingButton>

            {showButton && (
              <LoadingButton
                variant="contained"
                sx={{
                  color: "primary.contrastText",
                  backgroundColor: blue[800],
                  "&:hover": { backgroundColor: blue[900] },
                  minWidth: "40%",
                  marginTop: "2rem",
                }}
                onClick={onClickFunc}
                loading={loading}
              >
                {btnText}
              </LoadingButton>
            )}

            {showActivateButton && (
              <LoadingButton
                variant="contained"
                sx={{
                  color: "primary.contrastText",
                  backgroundColor: red[800],
                  "&:hover": { backgroundColor: red[900] },
                  width: "20%",
                  marginTop: "2rem",
                }}
                onClick={onActivateButtonClick}
                loading={activateLoading}
              >
                {activateBtnText}
              </LoadingButton>
            )}
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
};

export default CustomModal;
