import { Avatar, Paper, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { customColors } from "../../assets/styles/Theme";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { getAllPatients } from "../../utils/functions/patients";
import { HospitalPage } from "../../utils/others/AccessController";
import { LoadingButton } from "@mui/lab";
import CustomModal from "../../components/others/CustomModal";
import { toggleUserBlockStatus } from "../../utils/functions/general";
import { toast } from "react-toastify";

const AllPatients = () => {
  const { fetchUserData, userData } = useContext(AuthContext);
  const { setActiveSidebarText, setTopLabel } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [patients, setPatients] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [blockUnblockLoading, setBlockUnblockLoading] = useState(false);

  useEffect(() => {
    fetchUserData(localStorage.getItem("uid"));
    setActiveSidebarText("all-patients");
    setTopLabel("All Patients");
  }, [fetchUserData, setActiveSidebarText, setTopLabel]);

  useEffect(() => {
    const fetchDoctors = async () => {
      setLoading(true);
      try {
        const { patientRows, patientsData } = await getAllPatients();
        setRows(patientRows);
        setPatients(patientsData);
      } catch (error) {
        console.error("Error fetching patients:", error);
      } finally {
        setLoading(false);
      }
    };

    if (userData) {
      fetchDoctors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showModal = (id) => {
    const currentData = patients.find((item) => item.id === id);
    const newArr = [
      {
        key1: "ID",
        value1: id,
      },
      {
        key1: "Blocked",
        value1: currentData?.isBlocked ? "Yes" : "No",
      },
      {
        key1: "First Name",
        value1: currentData.firstName,
      },
      {
        key1: "Last Name",
        value1: currentData.lastName,
      },
      {
        key1: "Medications",
        value1: currentData.meds,
      },
      {
        key1: "Treatment",
        value1: currentData.treatment,
      },
      {
        key1: "Location",
        value1: currentData.location || "Not Available",
      },
      {
        key1: "Phone Number",
        value1: currentData.phoneNumber,
      },
      {
        key1: "Appointments",
        value1: currentData.appointments,
      },
      // {
      //   key1: "Schedule",
      //   value1: currentData.schedules,
      // },
    ];
    setModalData(newArr);
    setOpenModal(true);
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (params) => {
        return <Avatar src={params.row.avatar} />;
      },
    },
    { field: "firstName", headerName: "First Name", flex: 0.4 },
    { field: "lastName", headerName: "Last Name", flex: 0.4 },
    { field: "treatment", headerName: "Treatment", flex: 0.5 },
    { field: "meds", headerName: "Medications", flex: 0.5 },
    { field: "location", headerName: "Location", flex: 0.5 },
    { field: "phoneNumber", headerName: "Phone Number", flex: 0.5 },
    { field: "appointments", headerName: "Appointments", flex: 0.5 },
    {
      field: "View",
      headerName: "View",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <LoadingButton
            sx={{
              color: customColors.color_white,
              backgroundColor: customColors.color_primary,
              "&:hover": { backgroundColor: customColors.color_primary },
            }}
            onClick={() => showModal(params.row.id)}
          >
            View
          </LoadingButton>
        );
      },
    },
  ];

  const blockUserhandler = async (userId) => {
    try {
      setBlockUnblockLoading(true);
      await toggleUserBlockStatus(userId);
      setBlockUnblockLoading(false);
      setOpenModal(false);
    } catch (error) {
      setBlockUnblockLoading(false);
      toast.error(error.message);
    }
  };

  return (
    <HospitalPage>
      <SidebarWrapper>
        <Paper
          sx={{
            borderWidth: "1px",
            minHeight: "10vmax",
            height: "auto",
            borderRadius: "0.5rem",
            marginTop: "1rem",
            padding: "2rem 1rem",
            textAlign: "left",
            width: "100%",
          }}
        >
          {rows.length === 0 && !loading ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginTop: "3vmax",
                marginBottom: "3vmax",
                color: customColors.color_red,
              }}
            >
              No Nurses Present
            </Typography>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          )}
        </Paper>

        {openModal && (
          <CustomModal
            data={modalData}
            open={openModal}
            onClose={() => setOpenModal(false)}
            showButton={userData?.role === "admin"}
            btnText={modalData?.[1]?.value1 === "Yes" ? "Unblock" : "Block"}
            onClickFunc={() => blockUserhandler(modalData?.[0]?.value1)}
            loading={blockUnblockLoading}
          />
        )}
      </SidebarWrapper>
    </HospitalPage>
  );
};

export default AllPatients;
