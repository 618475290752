import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import BackendAPI from "../others/AxiosClient";
import { formatPostTime } from "../others/calendar.utils";

export const handlePayment = async (userId) => {
  const randomNumber = Math.floor(10000 + Math.random() * 90000);
  const paymentId = `${Date.now()}${randomNumber}`;
  const AMOUNT = 30;
  try {
    // Step 1: Create payment object in Firebase
    await setDoc(doc(db, "payments", paymentId), {
      status: "PENDING",
      amount: AMOUNT.toString(),
      currency: "USD",
      userId,
      createdAt: Date.now(),
      updatedAt: Date.now(),
    });
    console.log("here");

    // Step 2: Make API call to Whish
    const { data, status } = await BackendAPI.post("payments/create", {
      externalId: paymentId,
      amount: AMOUNT,
      type: "Monthly doctors's fee",
    });

    if (status === 200 && data) {
      return data.result;
    } else {
      throw new Error("Something went wrong! Please try again later.");
    }
  } catch (error) {
    console.error("Error during payment:", error);
    // Update Firebase payment object with "FAILED" status
    await deleteDoc(doc(db, "payments", paymentId));
    throw new Error(error?.message);
  }
};

export const getAllPaymentsWithUsers = async () => {
  try {
    // Fetch all payments from Firestore
    const paymentsSnapshot = await getDocs(collection(db, "payments"));
    const paymentsData = [];

    for (const paymentDoc of paymentsSnapshot.docs) {
      const paymentData = paymentDoc.data();
      const userId = paymentData.userId;

      // Fetch user associated with the userId
      const userDocRef = doc(db, "users", userId);
      const userSnapshot = await getDoc(userDocRef);
      const userData = userSnapshot.exists() ? userSnapshot.data() : null;

      paymentsData.push({
        id: paymentDoc.id,
        ...paymentData,
        user: userData, // Include user data in the payment object
      });
    }

    const paymentRows = paymentsData.map((p) => ({
      id: p.id,
      avatar: p?.user?.avatar,
      name: `${p.user?.firstName || "Not"} ${p.user?.lastName || "Available"}`,
      email: p.user?.email || "Not Available",
      role: p.user?.role || "Not Available",
      userStatus: p.user?.active ? "Active" : "Not Active",
      amount: `$${parseFloat(p.amount)?.toFixed(2)}`,
      createdAt: formatPostTime(p.createdAt),
      status: p.status,
    }));

    return { paymentsData, paymentRows };
  } catch (error) {
    console.error("Error fetching payments with users:", error);
    throw new Error(error);
  }
};
