import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Grid,
  Slide,
  TextField,
  Typography,
  Zoom,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import signinImage from "../../assets/auth/signin.jpg";
import { customColors } from "../../assets/styles/Theme";
import { AuthContext } from "../../contexts/Auth.context";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../utils/firebase/firebaseConfig";
import { toast } from "react-toastify";

const SignInPage = () => {
  const { userData, setLoggedIn, fetchUserData } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); // Access the `state` passed during redirection

  useEffect(() => {
    if (userData) {
      navigate("/dashboard");
    }
  }, [userData, navigate]);

  const [loginLoading, setLoginLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission
    setError(""); // Reset error
    setLoginLoading(true); // Start loading

    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      setLoggedIn(true); // Update logged-in state
      toast.success("Logged in successfully!");

      // Fetch user data after successful login
      await fetchUserData(user.uid); // Fetch user data using the uid
      const redirectPath = location.state?.from?.pathname || "/dashboard";
      navigate(redirectPath);
    } catch (error) {
      setError(
        error.message.includes("invalid-credential")
          ? "Invalid Credentials"
          : "Something Went Wrong"
      );
      toast.error(
        "Login failed! " + error.message.includes("invalid-credential")
          ? "Invalid Credentials"
          : "Something Went Wrong".message
      );
    } finally {
      setLoginLoading(false); // End loading state
    }
  };

  return (
    <Box sx={{ minHeight: "100vh !important" }}>
      <Slide direction="up" in={true} timeout={300} mountOnEnter unmountOnExit>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          sx={{ minHeight: "100vh" }}
        >
          {/* Image Section */}
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: { xs: "none", md: "block" },
              backgroundImage: `url('${signinImage}')`,
              backgroundSize: "cover",
              minHeight: "102vh",
            }}
          />

          <Zoom
            in={true}
            timeout={300}
            mountOnEnter
            unmountOnExit
            style={{ transitionDelay: "500ms" }}
          >
            {/* Sign-In Form Section */}
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    padding: "1rem",
                    marginTop: { xs: "1rem", md: "0" },
                    width: { xs: "90vw", md: "60vw" },
                    gap: 2,
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{ color: customColors.color_primary, my: 2 }}
                  >
                    Sign In
                  </Typography>
                  {error?.length > 0 && (
                    <Typography
                      variant="body1"
                      color="red"
                      align="center"
                      gutterBottom
                    >
                      {error}
                    </Typography>
                  )}
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ gap: 2 }}>
                      <TextField
                        fullWidth
                        label="Email"
                        variant="outlined"
                        required
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{ my: 1 }}
                      />
                      <TextField
                        fullWidth
                        label="Password"
                        variant="outlined"
                        required
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        sx={{ my: 1 }}
                        InputProps={{
                          endAdornment: (
                            <Button
                              onClick={() => setShowPassword(!showPassword)}
                              aria-label="toggle password visibility"
                            >
                              {showPassword ? (
                                <Visibility
                                  sx={{ color: customColors.color_primary }}
                                />
                              ) : (
                                <VisibilityOff
                                  sx={{ color: customColors.color_primary }}
                                />
                              )}
                            </Button>
                          ),
                        }}
                      />
                      <LoadingButton
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{
                          backgroundColor: customColors.color_primary,
                          "&:hover": {
                            backgroundColor: customColors.color_primary,
                          },
                          my: 1,
                        }}
                        type="submit"
                        loading={loginLoading}
                      >
                        sign in
                      </LoadingButton>
                      <Typography variant="body1" align="center" sx={{ my: 1 }}>
                        Don't have an account?{" "}
                        <Link
                          style={{
                            color: customColors.color_primary,
                            cursor: "pointer",
                            textDecoration: "none",
                          }}
                          to="/signup"
                        >
                          Register Here
                        </Link>
                      </Typography>
                      <Typography variant="body1" align="center" sx={{ my: 1 }}>
                        Forgot Password?{" "}
                        <Link
                          style={{
                            textDecoration: "none",
                            color: customColors.color_primary,
                          }}
                          to="/forgot-password"
                        >
                          Click Here
                        </Link>
                      </Typography>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Grid>
          </Zoom>
        </Grid>
      </Slide>
    </Box>
  );
};

export default SignInPage;
