import {
  ArrowRightAltOutlined,
  ChatBubbleOutline,
  Close,
  Favorite,
  IosShare,
  MoreVert,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";
import { customColors } from "../../assets/styles/Theme";
import { AuthContext } from "../../contexts/Auth.context";
import {
  addComment,
  deletePost,
  likePost,
  updatePost,
} from "../../utils/functions/posts";
import { formatPostTime } from "../../utils/others/calendar.utils";
import CommentCard from "./CommentCard";
import CustomButton, { CustomOutlinedButton } from "../others/CustomButton";

const PostCard = ({
  post,
  refetchFunc,
  isPostPage = false,
  showMyPosts = false,
}) => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);

  const [openModal, setOpenModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [likeLoading, setLikeLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [commentLoading, setCommentLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [content, setContent] = useState(post?.content);

  const postUrl = `https://wise-doctor.com/posts/${post?.id}`; // Generate post URL
  const postTitle = post?.content || "Check out this post!"; // Set a post title

  // Open modal with the selected media
  const handleMediaClick = (mediaUrl) => {
    setSelectedMedia(mediaUrl);
    setOpenModal(true);
  };

  // Close modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedMedia(null);
  };

  const handleLikePost = async () => {
    setLikeLoading(true);
    await likePost(post?.id, userData?.uid);
    setLikeLoading(false);
    refetchFunc();
  };

  const handleAddComment = async () => {
    setCommentLoading(true);
    await addComment(post?.id, userData?.uid, comment?.trim());
    setCommentLoading(false);
    setComment("");
    refetchFunc();
  };

  const handleEditPost = async () => {
    setEditLoading(true);
    await updatePost(post?.id, userData?.uid, content?.trim());
    setEditLoading(false);
    setOpenEditModal(false);
    refetchFunc();
  };

  const handleDeletePost = async () => {
    setDeleteLoading(true);
    await deletePost(post?.id, userData?.uid);
    setDeleteLoading(false);
    setOpenEditModal(false);
    navigate(-1);
  };

  return (
    <>
      <Card sx={{ mb: 2, borderRadius: 2, boxShadow: isPostPage ? 0 : 3 }}>
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            px: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
            <Avatar src={post?.user?.avatar} alt={""} sx={{ mr: 2 }} />
            <Box>
              <Typography variant="subtitle1" fontWeight="bold">
                {post?.user?.firstName} {post?.user?.lastName}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {formatPostTime(post?.timestamp)}
              </Typography>
            </Box>
          </Box>

          {post?.userId === userData?.uid && (
            <IconButton onClick={() => setOpenEditModal(!openEditModal)}>
              <MoreVert />
            </IconButton>
          )}
        </Box>

        {/* Content */}
        {post?.content && (
          <CardContent
            sx={{ cursor: "pointer" }}
            onClick={() => navigate(`/posts/${post.id}`)}
          >
            <Typography variant="body1">
              {post?.content?.length > 303
                ? `${post?.content?.slice(0, 300)}...`
                : post?.content}
            </Typography>
          </CardContent>
        )}

        {/* Media */}
        {post?.media && post?.media.length > 0 && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: `repeat(auto-fit, minmax(100px, 1fr))`,
              gap: 1,
              p: 2,
            }}
          >
            {post?.media?.map((url, index) => (
              <Box
                key={index}
                onClick={() => handleMediaClick(url)}
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
              >
                <CardMedia
                  component={
                    url.endsWith(".mp4") || url.endsWith(".webm")
                      ? "video"
                      : "img"
                  }
                  src={url}
                  controls={url.endsWith(".mp4") || url.endsWith(".webm")}
                  sx={{
                    width: "100%",
                    height: "150px",
                    objectFit: "cover",
                    transition: "transform 0.3s",
                    "&:hover": { transform: "scale(1.05)" },
                  }}
                />
              </Box>
            ))}
          </Box>
        )}

        {/* Actions */}
        <Box sx={{ display: "flex", justifyContent: "space-between", p: 2 }}>
          <Box>
            <IconButton onClick={handleLikePost}>
              {likeLoading ? (
                <CircularProgress size={14} sx={{ color: "#cd486b" }} />
              ) : (
                <Favorite
                  sx={{
                    color: post?.likes?.includes(userData.uid)
                      ? "#cd486b"
                      : grey[500],
                  }}
                />
              )}
            </IconButton>
            <Typography component="span">{post?.likes?.length || 0}</Typography>
          </Box>
          <Box>
            <IconButton onClick={() => navigate(`/posts/${post.id}`)}>
              <ChatBubbleOutline
                sx={{
                  color: grey[500],
                }}
              />
            </IconButton>
            <Typography component="span">
              {post?.comments?.length || 0}
            </Typography>
          </Box>
          {/* TODO: Share the post using react-share */}
          <IconButton onClick={() => setOpenShareModal(true)}>
            <IosShare sx={{ color: grey[500] }} />
          </IconButton>
        </Box>

        {showMyPosts && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: "75%" }}>
              <CustomOutlinedButton
                label={"See All Posts"}
                endIcon={<ArrowRightAltOutlined />}
                onClick={() => navigate("/my-posts")}
              />
            </Box>
          </Box>
        )}
      </Card>

      {isPostPage && (
        <Box sx={{ textAlign: "right" }}>
          <TextField
            multiline
            rows={3}
            fullWidth
            placeholder="Add Comment..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <LoadingButton
            sx={{
              paddingX: "1rem",
              borderRadius: "1rem",
              backgroundColor: customColors.color_primary,
              color: customColors.color_white,
              ":hover": { backgroundColor: customColors.color_primary },
              my: "0.7rem",
            }}
            loading={commentLoading}
            disabled={commentLoading || comment.trim().length === 0}
            onClick={handleAddComment}
          >
            Comment
          </LoadingButton>
        </Box>
      )}

      {isPostPage &&
        post?.comments &&
        post?.comments?.length > 0 &&
        post?.comments?.map((comment, index) => {
          if (comment !== null) {
            return (
              <CommentCard
                comment={comment}
                key={index}
                refetchFunc={refetchFunc}
              />
            );
          } else {
            return null;
          }
        })}

      {/* Modal for media preview */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%", md: "60%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 3,
            maxHeight: "90vh",
            overflowY: "auto",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Post Details</Typography>
            <IconButton onClick={handleCloseModal}>
              <Close />
            </IconButton>
          </Box>

          {/* Media in modal */}
          {selectedMedia && (
            <CardMedia
              component={
                selectedMedia.endsWith(".mp4") ||
                selectedMedia.endsWith(".webm")
                  ? "video"
                  : "img"
              }
              src={selectedMedia}
              controls={
                selectedMedia.endsWith(".mp4") ||
                selectedMedia.endsWith(".webm")
              }
              sx={{
                width: "100%",
                maxHeight: "350px",
                borderRadius: 2,
                mb: 2,
                objectFit: "contain",
              }}
            />
          )}

          {/* Post content */}
          {post?.content && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              {post?.content}
            </Typography>
          )}

          {/* Additional media previews in modal */}
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
            }}
          >
            {post?.media?.map((url, index) => (
              <CardMedia
                key={index}
                component={
                  url.endsWith(".mp4") || url.endsWith(".webm")
                    ? "video"
                    : "img"
                }
                src={url}
                controls={url.endsWith(".mp4") || url.endsWith(".webm")}
                sx={{
                  width: "80px",
                  height: "80px",
                  objectFit: "cover",
                  borderRadius: 2,
                  cursor: "pointer",
                  border: url === selectedMedia ? "2px solid #3f51b5" : "none",
                }}
                onClick={() => setSelectedMedia(url)}
              />
            ))}
          </Box>
        </Box>
      </Modal>

      <Modal open={openShareModal} onClose={() => setOpenShareModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "80%", sm: "60%", md: "40%" },
            bgcolor: "background.paper",
            borderRadius: 2,
            boxShadow: 24,
            p: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h6" sx={{ mb: 2 }}>
            Share this post
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              gap: 2,
              flexWrap: "wrap",
            }}
          >
            <FacebookShareButton url={postUrl} quote={postTitle}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            <LinkedinShareButton url={postUrl} quote={postTitle}>
              <LinkedinIcon size={40} round />
            </LinkedinShareButton>
            <TelegramShareButton url={postUrl} title={postTitle}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>
            <TwitterShareButton url={postUrl} title={postTitle}>
              <XIcon size={40} round />
            </TwitterShareButton>
            <EmailShareButton url={postUrl} subject={postTitle}>
              <EmailIcon size={40} round />
            </EmailShareButton>
            <WhatsappShareButton url={postUrl} title={postTitle}>
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>
          </Box>
        </Box>
      </Modal>

      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "400px" },
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
            <Typography variant="h6">Update Post</Typography>
            <IconButton onClick={() => setOpenEditModal(false)}>
              <Close />
            </IconButton>
          </Box>

          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="What's on your mind?"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ mb: 2 }}
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 0.7,
              flexDirection: "column",
            }}
          >
            <CustomButton
              loading={editLoading}
              disabled={editLoading || content?.trim()?.length === 0}
              label={"Update"}
              onClick={handleEditPost}
            />
            <CustomOutlinedButton
              loading={deleteLoading}
              disabled={deleteLoading}
              label={"Delete Post"}
              onClick={handleDeletePost}
              color={customColors.color_red}
            />
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default PostCard;
