import {
  collection,
  addDoc,
  doc,
  getDoc,
  query,
  orderBy,
  getDocs,
  where,
  deleteDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
} from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, storage } from "../firebase/firebaseConfig";
import { IdGenerator } from "../others/calendar.utils";

export const createPost = async (userId, content, mediaFiles) => {
  try {
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      const role = userData.role;
      if (role !== "doctor" && role !== "nurse") {
        throw new Error("Only doctors or nurses can create posts.");
      }

      // Upload media files to Firebase Storage
      const mediaUrls = await Promise.all(
        mediaFiles.map(async (file) => {
          const storageRef = ref(
            storage,
            `posts/${userId}/${Date.now()}_${file.name}`
          );
          await uploadBytes(storageRef, file);
          return await getDownloadURL(storageRef);
        })
      );

      // Save post data to Firestore
      const postDoc = await addDoc(collection(db, "posts"), {
        userId,
        role,
        content,
        media: mediaUrls,
        timestamp: Date.now(),
        updatedAt: Date.now(),
        likes: [],
        comments: [],
      });

      console.log("Post created successfully:", postDoc.id);
    } else {
      throw new Error("User not found");
    }
  } catch (error) {
    console.error("Error creating post:", error.message);
    throw error;
  }
};

// {
//     "userId": "string", // ID of the user who created the post
//     "role": "doctor" | "nurse", // Only doctors or nurses can post
//     "content": "string", // Post text content
//     "media": ["url1", "url2"], // Array of URLs of images/videos
//     "timestamp": "Date", // When the post was created
//      "updatedAt": "Date", // When the post was updated
//     "likes": ["userId1", "userId2"], // Array of user IDs who liked the post
//     "comments": [
//       {
//         "userId": "string",
//         "content": "string",
//         "timestamp": "Date"
//       }
//     ] // Array of comments
//   }
export const fetchPosts = async () => {
  try {
    const postsQuery = query(
      collection(db, "posts"),
      orderBy("timestamp", "desc")
    );
    const querySnapshot = await getDocs(postsQuery);

    const posts = await Promise.all(
      querySnapshot.docs.map(async (item) => {
        const postData = item.data();
        const userRef = doc(db, "users", postData.userId);
        const userSnapshot = await getDoc(userRef);

        return {
          id: item.id,
          ...postData,
          user: userSnapshot.exists() ? userSnapshot.data() : null, // Add user data or null if not found
        };
      })
    );

    return posts;
  } catch (error) {
    console.error("Error fetching posts:", error.message);
    throw error;
  }
};

export const fetchMyPosts = async (userId) => {
  try {
    const postsQuery = query(
      collection(db, "posts"),
      where("userId", "==", userId)
    );
    const querySnapshot = await getDocs(postsQuery);

    const posts = await Promise.all(
      querySnapshot.docs.map(async (item) => {
        const postData = item.data();
        const userRef = doc(db, "users", postData.userId);
        const userSnapshot = await getDoc(userRef);

        return {
          id: item.id,
          ...postData,
          user: userSnapshot.exists() ? userSnapshot.data() : null, // Add user data or null if not found
        };
      })
    );

    // posts.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    return posts;
  } catch (error) {
    console.error("Error fetching posts:", error.message);
    throw error;
  }
};

export const fetchSinglePost = async (postId) => {
  try {
    const postRef = doc(db, "posts", postId);
    const postSnapshot = await getDoc(postRef);

    if (!postSnapshot.exists()) {
      throw new Error("The post is not available at the moment.");
    }

    const postData = postSnapshot.data();

    // Fetch the user data for the post author
    const userRef = doc(db, "users", postData.userId);
    const userSnapshot = await getDoc(userRef);

    const userData = userSnapshot.exists() ? userSnapshot.data() : null;

    // Process comments to include user data
    const commentsWithUser = await Promise.all(
      (postData.comments || []).map(async (comment) => {
        const commentUserRef = doc(db, "users", comment.userId);
        const commentUserSnapshot = await getDoc(commentUserRef);

        // Embed the user data into the comment
        const commentUserData = commentUserSnapshot.exists()
          ? commentUserSnapshot.data()
          : null;

        // Process replies in the comment
        const repliesWithUser = await Promise.all(
          (comment.replies || []).map(async (reply) => {
            const replyUserRef = doc(db, "users", reply.userId);
            const replyUserSnapshot = await getDoc(replyUserRef);

            const replyUserData = replyUserSnapshot.exists()
              ? replyUserSnapshot.data()
              : null;

            return { ...reply, user: replyUserData };
          })
        );

        return { ...comment, user: commentUserData, replies: repliesWithUser };
      })
    );

    return {
      id: postId,
      ...postData,
      user: userData,
      comments: commentsWithUser,
    };
  } catch (error) {
    console.error("Error fetching a single post:", error.message);
    throw error;
  }
};

export const deletePost = async (postId, userId) => {
  try {
    // Reference to the post
    const postRef = doc(db, "posts", postId);
    const postSnapshot = await getDoc(postRef);

    if (!postSnapshot.exists()) {
      throw new Error("Post not found.");
    }

    const postData = postSnapshot.data();

    // Reference to the user
    const userRef = doc(db, "users", userId);
    const userSnapshot = await getDoc(userRef);

    if (!userSnapshot.exists()) {
      throw new Error("User not found.");
    }

    const userData = userSnapshot.data();

    // Authorization check: user must own the post or be an admin
    if (postData.userId !== userId && userData.role !== "admin") {
      throw new Error("You're not authorized to delete this post.");
    }

    // Delete associated media if any
    const allMedias = postData.media;
    if (allMedias && allMedias.length > 0) {
      for (const media of allMedias) {
        const mediaRef = ref(storage, media); // Reference to media in storage
        await deleteObject(mediaRef); // Delete the media file
      }
    }

    // Delete the post
    await deleteDoc(postRef);

    // Fetch updated posts
    let postsQuery = query(
      collection(db, "posts"),
      where("userId", "==", userId),
      orderBy("timestamp", "desc")
    );

    if (userData.role === "admin") {
      postsQuery = query(collection(db, "posts"), orderBy("timestamp", "desc"));
    }

    const querySnapshot = await getDocs(postsQuery);

    const updatedPosts = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return updatedPosts; // Return the updated list of posts
  } catch (error) {
    console.error("Error deleting post:", error.message);
    throw error;
  }
};

export const updatePost = async (postId, userId, newContent) => {
  try {
    // Reference to the post
    const postRef = doc(db, "posts", postId);
    const postSnapshot = await getDoc(postRef);

    if (!postSnapshot.exists()) {
      throw new Error("Post not found.");
    }

    const postData = postSnapshot.data();

    // Authorization check: Ensure the user owns the post
    if (postData.userId !== userId) {
      throw new Error("You're not authorized to update this post.");
    }

    // Update only the content of the post
    await updateDoc(postRef, {
      content: newContent,
      updatedAt: Date.now(),
    });

    console.log("Post updated successfully.");
    return { id: postId, ...postData, content: newContent };
  } catch (error) {
    console.error("Error updating post:", error.message);
    throw error;
  }
};

export const likePost = async (postId, userId) => {
  try {
    const postRef = doc(db, "posts", postId);
    const postSnapshot = await getDoc(postRef);

    if (!postSnapshot.exists()) {
      throw new Error("Post not found.");
    }

    const postData = postSnapshot.data();
    const likes = postData.likes || [];

    const updatedLikes = likes.includes(userId)
      ? arrayRemove(userId) // Unlike if already liked
      : arrayUnion(userId); // Add like if not already liked

    await updateDoc(postRef, { likes: updatedLikes });

    console.log("Post like updated.");
    return updatedLikes; // Return updated likes
  } catch (error) {
    console.error("Error liking post:", error.message);
    throw error;
  }
};

export const addComment = async (postId, userId, commentText) => {
  try {
    const postRef = doc(db, "posts", postId);
    const comment = {
      id: IdGenerator(),
      userId,
      text: commentText,
      timestamp: new Date(),
      likes: [],
      replies: [], // For nested comments
    };

    await updateDoc(postRef, {
      comments: arrayUnion(comment),
    });

    console.log("Comment added.");
    return comment;
  } catch (error) {
    console.error("Error adding comment:", error.message);
    throw error;
  }
};

export const replyToComment = async (postId, commentId, userId, replyText) => {
  try {
    const postRef = doc(db, "posts", postId);
    const postSnapshot = await getDoc(postRef);

    if (!postSnapshot.exists()) {
      throw new Error("Post not found.");
    }

    const postData = postSnapshot.data();
    const comments = postData.comments || [];

    // Recursively add a reply to the correct comment
    const addReplyRecursive = (comments, commentId) => {
      return comments.map((comment) => {
        if (comment.id === commentId) {
          const reply = {
            id: IdGenerator(),
            userId,
            text: replyText,
            timestamp: new Date(),
            likes: [],
            replies: [],
          };
          return {
            ...comment,
            replies: [...comment.replies, reply],
          };
        }
        return {
          ...comment,
          replies: addReplyRecursive(comment.replies, commentId),
        };
      });
    };

    const updatedComments = addReplyRecursive(comments, commentId);

    await updateDoc(postRef, { comments: updatedComments });

    console.log("Reply added.");
    return updatedComments;
  } catch (error) {
    console.error("Error replying to comment:", error.message);
    throw error;
  }
};

export const likeComment = async (postId, commentId, userId) => {
  try {
    const postRef = doc(db, "posts", postId);
    const postSnapshot = await getDoc(postRef);

    if (!postSnapshot.exists()) {
      throw new Error("Post not found.");
    }

    const postData = postSnapshot.data();
    const comments = postData.comments || [];

    const toggleLike = (comments, commentId) => {
      return comments.map((comment) => {
        if (comment.id === commentId) {
          const isLiked = comment.likes.includes(userId);
          return {
            ...comment,
            likes: isLiked
              ? comment.likes.filter((id) => id !== userId)
              : [...comment.likes, userId],
          };
        }
        return {
          ...comment,
          replies: toggleLike(comment.replies, commentId),
        };
      });
    };

    const updatedComments = toggleLike(comments, commentId);

    await updateDoc(postRef, { comments: updatedComments });

    return updatedComments;
  } catch (error) {
    console.error("Error liking comment:", error.message);
    console.error(error);
    throw error;
  }
};

export const likeReply = async (postId, commentId, replyId, userId) => {
  try {
    const postRef = doc(db, "posts", postId);
    const postSnapshot = await getDoc(postRef);

    if (!postSnapshot.exists()) {
      throw new Error("Post not found.");
    }

    const postData = postSnapshot.data();
    const comments = postData.comments || [];

    const toggleLikeReply = (comments, commentId, replyId) => {
      return comments.map((comment) => {
        if (comment.id === commentId) {
          const updatedReplies = comment.replies.map((reply) => {
            if (reply.id === replyId) {
              const isLiked = reply.likes.includes(userId);
              return {
                ...reply,
                likes: isLiked
                  ? reply.likes.filter((id) => id !== userId) // Unlike
                  : [...reply.likes, userId], // Like
              };
            }
            return reply;
          });

          return {
            ...comment,
            replies: updatedReplies,
          };
        }

        return {
          ...comment,
          replies: toggleLikeReply(comment.replies, commentId, replyId),
        };
      });
    };

    const updatedComments = toggleLikeReply(comments, commentId, replyId);

    await updateDoc(postRef, { comments: updatedComments });

    return updatedComments;
  } catch (error) {
    console.error("Error liking reply:", error.message);
    throw error;
  }
};
