import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../components/loading/Loader";
import { db } from "../utils/firebase/firebaseConfig";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation(); // To get the current location (e.g., /posts/dkgfne...)

  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  const isPublic = [
    "/",
    "/signin",
    "/signup",
    "/privacy-policy",
    "/forgot-password",
    "/payment?st=success",
    "/payment?st=failed",
  ].some((path) => window.location.href.endsWith(path));

  useEffect(() => {
    if (!loading) {
      if (!loggedIn && !isPublic && !userData) {
        navigate("/signin", { state: { from: location } }); // Pass the current location to state
      }
    }
  }, [isPublic, loading, loggedIn, userData, navigate, location]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);
      if (user) {
        setLoggedIn(true);
        localStorage.setItem("uid", user.uid);
        await fetchUserData(user.uid);
      } else {
        setLoggedIn(false);
        setUserData(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const fetchUserData = async (uid) => {
    try {
      const userDoc = await getDoc(doc(db, "users", uid));
      if (userDoc.exists()) {
        const currentData = userDoc.data();
        setUserData({ uid, ...currentData });
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error getting document:", error);
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <AuthContext.Provider
      value={{ userData, setUserData, loggedIn, setLoggedIn, fetchUserData }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
