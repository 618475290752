import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Zoom,
  IconButton,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { ChevronLeftOutlined, PhotoCamera } from "@mui/icons-material";
import { SignupContext } from "../../contexts/SignUp.context";
import { LoadingButton } from "@mui/lab";
import { customColors } from "../../assets/styles/Theme";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../utils/firebase/firebaseConfig";
import uploadImageToFirebase from "../../utils/firebase/uploadImageToFirebase";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/Auth.context";
import BackendAPI from "../../utils/others/AxiosClient";
import { getAllHospitals } from "../../utils/functions/hospitals";
import { SmallLoader } from "../loading/Loader";

const DoctorAuthInput = ({ setShowNextComponent }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { firstName, lastName, email, phoneNumber, password, role } =
    useContext(SignupContext);
  const { userData, setLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    if (userData) {
      navigate("/dashboard");
    }
    (async () => {
      setHospitalLoading(true);
      const { hospitalsData } = await getAllHospitals();
      setAllHospitals(hospitalsData);
      setHospitalLoading(false);
    })();
  }, [userData, navigate]);
  const [photoURL, setPhotoURL] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [qualification, setQualification] = useState("");
  const [hospital, setHospital] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [withHospital, setWithHospital] = useState(false);
  const [hospitalId, setHospitalId] = useState("");
  const [hospitalLoading, setHospitalLoading] = useState(false);
  const [allHospitals, setAllHospitals] = useState([]);

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhotoURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true);

    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const currentUser = userCredential.user;

      // Upload avatar image if provided
      let downloadURL = "";
      if (photoURL?.length > 0) {
        downloadURL = await uploadImageToFirebase(photoURL);
      }

      // Create user document in Firestore
      await setDoc(doc(db, "users", currentUser.uid), {
        firstName,
        lastName,
        phoneNumber,
        email,
        role,
        avatar: downloadURL,
        meds: "",
        diseases: "",
        treatment: "",
        specialization,
        qualification,
        hospital,
        schedules: [],
        isBlocked: false,
        active: false,
        activationTime: null,
        withHospital,
      });

      if (withHospital && (hospitalId !== "" || !hospitalId)) {
        const hospitalRef = doc(db, "hospitals", hospitalId); // Reference to the hospital document
        const hospitalSnapshot = await getDoc(hospitalRef);

        // Object to add to hospital document
        const userObject = {
          userId: currentUser.uid,
          role,
          accepted: false, // Default to false, modify as needed
          joiningTime: Date.now(),
          left: false,
          leftTime: null, // Null since the user has not left yet
          leavingReason: "",
        };

        if (hospitalSnapshot.exists()) {
          await updateDoc(hospitalRef, {
            users: arrayUnion(userObject), // Add the new user to the array
          });
        } else {
          await setDoc(hospitalRef, {
            users: [userObject], // Initialize with the user object inside an array
          });
        }
      }

      toast.success("Signed Up successfully!");
      await BackendAPI.post("/users/send-signup-mail", {
        name: `${
          !firstName?.toLowerCase()?.startsWith("dr.") ? "Dr. " : ""
        } ${firstName} ${lastName}`,
        email,
      });
      const redirectPath = location.state?.from?.pathname || "/dashboard";
      navigate(redirectPath);
    } catch (error) {
      console.log("Error in doctor sign up::", error);
      setError(error?.message);
      toast.error(error?.message);
    } finally {
      setLoading(false);
      setLoggedIn(true);
    }
  };

  return (
    <Box sx={{ minHeight: "100% !important", width: "100%" }}>
      <Zoom in={true} timeout={300} mountOnEnter unmountOnExit>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              padding: "1rem",
              marginTop: { xs: "1rem", md: "0" },
              width: { xs: "90vw", md: "60vw" },
              gap: 2,
            }}
          >
            <Typography
              variant="h2"
              sx={{ color: customColors.color_primary, my: 2 }}
              textTransform="capitalize"
            >
              {role} Registration
            </Typography>

            <Box sx={{ gap: 2 }}>
              <Box sx={{ textAlign: "center", mb: 2 }}>
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                  sx={{
                    border: photoURL ? 0 : 2,
                    borderStyle: "dashed",
                    borderRadius: "50%",
                    height: 120,
                    width: 120,
                    margin: "auto",
                    overflow: "hidden",
                  }}
                >
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleImageUpload}
                  />
                  {photoURL ? (
                    <img
                      src={photoURL}
                      alt="Profile"
                      style={{ width: "100%", height: "100%" }}
                    />
                  ) : (
                    <PhotoCamera sx={{ fontSize: 60 }} />
                  )}
                </IconButton>
              </Box>
              <TextField
                fullWidth
                label="Specialization"
                required
                variant="outlined"
                value={specialization}
                onChange={(e) => setSpecialization(e.target.value)}
                sx={{ my: 1 }}
              />
              <TextField
                fullWidth
                required
                label="Qualification"
                variant="outlined"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
                sx={{ my: 1 }}
              />
              <Box sx={{ my: 1 }}>
                <FormControl>
                  <FormLabel
                    id="demo-row-radio-buttons-group-label"
                    defaultValue={false}
                  >
                    Are you associated with any hospital?
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    onChange={(e) => setWithHospital(e.target.value === "true")}
                  >
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          sx={{
                            color: customColors.color_primary,
                            "&.Mui-checked": {
                              color: customColors.color_primary,
                            },
                          }}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          sx={{
                            color: customColors.color_primary,
                            "&.Mui-checked": {
                              color: customColors.color_primary,
                            },
                          }}
                        />
                      }
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>

              {withHospital && (
                <Box sx={{ my: 1 }}>
                  {hospitalLoading ? (
                    <SmallLoader />
                  ) : (
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-helper-label">
                        Hospitals
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        value={hospital}
                        label="Hospitals"
                        onChange={(e) => setHospital(e.target.value)}
                      >
                        {allHospitals.map((hospital) => (
                          <MenuItem
                            key={hospital.id}
                            sx={{ textTransform: "capitalize" }}
                            value={hospital.id}
                            onClick={() => setHospitalId(hospital?.id)}
                          >
                            {hospital.name}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        Please select a hospital from these options.
                      </FormHelperText>
                    </FormControl>
                  )}
                </Box>
              )}
              {error?.length > 0 && (
                <Typography
                  variant="body1"
                  color="red"
                  align="center"
                  gutterBottom
                >
                  {error}
                </Typography>
              )}
              <LoadingButton
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                loading={loading}
                sx={{
                  backgroundColor: customColors.color_primary,
                  "&:hover": {
                    backgroundColor: customColors.color_primary,
                  },
                  my: 1,
                }}
              >
                Register
              </LoadingButton>
              <LoadingButton
                startIcon={<ChevronLeftOutlined />}
                fullWidth
                variant="outlined"
                color={customColors.color_primary}
                onClick={() => setShowNextComponent(false)}
                sx={{
                  color: customColors.color_primary,
                  my: 1,
                }}
              >
                Back
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Zoom>
    </Box>
  );
};

export default DoctorAuthInput;
