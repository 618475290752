import React, { useCallback, useContext, useEffect, useState } from "react";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { fetchMyPosts } from "../../utils/functions/posts";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { SmallLoader } from "../../components/loading/Loader";
import PostCard from "../../components/cards/PostCard";

const MyPosts = () => {
  const { userData } = useContext(AuthContext);
  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);

  const getAllMyPosts = useCallback(async () => {
    setLoading(true);
    const data = await fetchMyPosts(userData.uid);
    setPosts(data);
    setLoading(false);
  }, [userData.uid]);

  useEffect(() => {
    setShowBottomNavigator(false);
    setTopLabel("My Posts");
    setActiveSidebarText("profile");
    getAllMyPosts();
  }, [
    getAllMyPosts,
    setActiveSidebarText,
    setShowBottomNavigator,
    setTopLabel,
  ]);

  const silentPostFetching = async () => {
    const data = await fetchMyPosts(userData.uid);
    setPosts(data);
  };
  return (
    <SidebarWrapper>
      {loading ? (
        <SmallLoader />
      ) : (
        <>
          {posts.map((post, index) => (
            <PostCard
              key={index}
              post={post}
              refetchFunc={silentPostFetching}
            />
          ))}
        </>
      )}
    </SidebarWrapper>
  );
};

export default MyPosts;
