import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase/firebaseConfig";
import { orderSchedules } from "../others/service.utils";

export const getAllNurses = async () => {
  try {
    const doctorsQuery = query(
      collection(db, "users"),
      where("role", "==", "nurse")
    );
    const querySnapshot = await getDocs(doctorsQuery);

    const nursesData = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const appointmentsQuery = query(
          collection(db, "userAppointments"),
          where("serviceId", "==", doc.id)
        );
        const aptmtquerySnapshot = await getDocs(appointmentsQuery);
        let appointments = aptmtquerySnapshot.size;
        return { id: doc.id, ...doc.data(), appointments };
      })
    );

    const nurseRows = nursesData.map((d) => ({
      id: d.id,
      firstName: d.firstName,
      lastName: d.lastName,
      active: d.active ? "Yes" : "No",
      specialization: d.specialization,
      qualification: d.qualification,
      location: d.location,
      phoneNumber: d.phoneNumber,
      appointments: d.appointments,
      schedules: orderSchedules(d.schedules),
      avatar: d.avatar,
    }));

    return { nursesData, nurseRows };
  } catch (error) {
    console.error("Error fetching doctors:", error);
    throw new Error(error.message);
  }
};
