import { LoadingButton } from "@mui/lab";
import { Avatar, Paper, Typography } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { customColors } from "../../assets/styles/Theme";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import { AuthContext } from "../../contexts/Auth.context";
import { AdminPage } from "../../utils/others/AccessController";
import { getAllPaymentsWithUsers } from "../../utils/functions/payments";
import CustomModal from "../../components/others/CustomModal";
import { toast } from "react-toastify";
import BackendAPI from "../../utils/others/AxiosClient";

const AllPayments = () => {
  const { fetchUserData, userData } = useContext(AuthContext);
  const { setActiveSidebarText, setTopLabel } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [payments, setPayments] = useState([]);
  const [modalData, setModalData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [collectLoading, setCollectLoading] = useState(false);

  useEffect(() => {
    fetchUserData(localStorage.getItem("uid"));
    setActiveSidebarText("all-payments");
    setTopLabel("All Payments");
  }, [fetchUserData, setActiveSidebarText, setTopLabel]);

  const silentFetchPayments = async () => {
    try {
      const { paymentsData, paymentRows } = await getAllPaymentsWithUsers();
      setRows(paymentRows);
      setPayments(paymentsData);
    } catch (error) {
      console.error("Error fetching payments:", error);
    }
  };
  useEffect(() => {
    const fetchPayments = async (load) => {
      if (load) {
        setLoading(true);
      }
      try {
        const { paymentsData, paymentRows } = await getAllPaymentsWithUsers();
        setRows(paymentRows);
        setPayments(paymentsData);
      } catch (error) {
        console.error("Error fetching payments:", error);
      } finally {
        setLoading(false);
      }
    };
    if (userData) {
      fetchPayments(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      field: "#",
      headerName: "Profile",
      flex: 0.3,
      renderCell: (params) => {
        return <Avatar src={params.row.avatar} />;
      },
    },
    { field: "name", headerName: "Name", flex: 0.4 },
    { field: "email", headerName: "Email", flex: 0.8 },
    { field: "userStatus", headerName: "Active", flex: 0.4 },
    { field: "role", headerName: "Role", flex: 0.4 },
    { field: "amount", headerName: "Amount", flex: 0.4 },
    { field: "createdAt", headerName: "Time", flex: 0.4 },
    { field: "status", headerName: "Payment Status", flex: 0.4 },
    {
      field: "View",
      headerName: "View",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <LoadingButton
            sx={{
              color: customColors.color_white,
              backgroundColor: customColors.color_primary,
              "&:hover": { backgroundColor: customColors.color_primary },
            }}
            onClick={() => showModal(params.row.id)}
          >
            View
          </LoadingButton>
        );
      },
    },
  ];

  const showModal = (id) => {
    const currentData = payments.find((item) => item.id === id);
    const newArr = [
      {
        key1: "ID",
        value1: id,
      },
      {
        key1: "Payment Stauts",
        value1: currentData?.status,
      },
      {
        key1: "Active Status",
        value1: currentData?.user?.active ? "Active" : "Inactive",
      },
      {
        key1: "Blocked",
        value1: currentData?.user?.isBlocked ? "Yes" : "No",
      },
      {
        key1: "First Name",
        value1: currentData?.user?.firstName,
      },
      {
        key1: "Last Name",
        value1: currentData?.user?.lastName,
      },
      {
        key1: "Email",
        value1: currentData?.user?.email,
      },
      {
        key1: "Specialization",
        value1: currentData?.user?.specialization,
      },
      {
        key1: "Qualification",
        value1: currentData?.user?.qualification,
      },
      {
        key1: "Location",
        value1: currentData?.user?.location || "Not Available",
      },
      {
        key1: "Phone Number",
        value1: currentData?.user?.phoneNumber,
      },
      {
        key1: "Amount",
        value1: `${parseFloat(currentData?.amount).toFixed(2)}`,
      },
    ];
    setModalData(newArr);
    setOpenModal(true);
  };

  const checkPaymentStatusHandler = async (id) => {
    try {
      setCollectLoading(true);
      const { data, status } = await BackendAPI.get(`/payments/collect/${id}`);
      if (status === 200 && data) {
        toast.success(data?.message);
        setCollectLoading(true);
        silentFetchPayments();
      }
      setCollectLoading(true);
      setOpenModal(false);
    } catch (error) {
      setCollectLoading(true);
      toast.error("Something went wrong! Please try again later.");
    }
  };

  return (
    <AdminPage>
      <SidebarWrapper>
        <Paper
          sx={{
            borderWidth: "1px",
            minHeight: "10vmax",
            height: "auto",
            borderRadius: "0.5rem",
            marginTop: "1rem",
            padding: "2rem 1rem",
            textAlign: "left",
            width: "100%",
          }}
        >
          {rows.length === 0 && !loading ? (
            <Typography
              variant="h6"
              sx={{
                textAlign: "center",
                marginTop: "3vmax",
                marginBottom: "3vmax",
                color: customColors.color_red,
              }}
            >
              No Payments Present
            </Typography>
          ) : (
            <DataGrid
              rows={rows}
              columns={columns}
              slots={{
                toolbar: GridToolbar,
              }}
            />
          )}
        </Paper>

        {openModal && (
          <CustomModal
            data={modalData}
            open={openModal}
            onClose={() => setOpenModal(false)}
            showButton={
              userData?.role === "admin" &&
              modalData?.[1]?.value1 === "PENDING" &&
              modalData?.[2]?.value1 !== "Active"
            }
            btnText="Fetch payment Status"
            loading={collectLoading}
            onClickFunc={() =>
              checkPaymentStatusHandler(modalData?.[0]?.value1)
            }
          />
        )}
      </SidebarWrapper>
    </AdminPage>
  );
};

export default AllPayments;
