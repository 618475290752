import { doc, updateDoc } from "firebase/firestore";
import { getUserByUID } from "./general";
import { db } from "../firebase/firebaseConfig";
import { toast } from "react-toastify";

export const toggleUserActivation = async (userId) => {
  try {
    const currentUser = await getUserByUID(userId);

    if (
      currentUser &&
      (currentUser?.role === "doctor" || currentUser?.role === "nurse")
    ) {
      const isActive = currentUser.active;
      await updateDoc(doc(db, "users", userId), {
        active: !isActive,
        activationTime: isActive ? null : Date.now(), //if active then put null and if inactive then (put the current time and activate)
      });
      if (isActive) {
        toast.error(`${currentUser.firstName} deactivated successfully`);
      } else {
        toast.success(`${currentUser.firstName} activated successfully`);
      }
    }
  } catch (error) {
    console.error("Error Toggling User activation:", error);
    throw new Error(error.message);
  }
};
