import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActiveSidebarContext } from "../../contexts/ActiveSidebarContext";
import SidebarWrapper from "../../components/wrapper/SidebarWrapper";
import { fetchSinglePost } from "../../utils/functions/posts";
import PostCard from "../../components/cards/PostCard";
import { SmallLoader } from "../../components/loading/Loader";
import { toast } from "react-toastify";

const SinglePost = () => {
  const { postId } = useParams();
  const navigate = useNavigate();

  const { setShowBottomNavigator, setTopLabel, setActiveSidebarText } =
    useContext(ActiveSidebarContext);

  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState(null);

  const getPost = useCallback(
    async (postId) => {
      try {
        setTopLabel("Post");
        const data = await fetchSinglePost(postId);
        setPost(data);
        setLoading(false);
      } catch (error) {
        toast.error(error?.message);
        navigate(-1);
      }
    },
    [navigate, setTopLabel]
  );

  useEffect(() => {
    setShowBottomNavigator(false);
    setTopLabel("Post");
    setActiveSidebarText("post");
    getPost(postId);
  }, [
    getPost,
    postId,
    setActiveSidebarText,
    setShowBottomNavigator,
    setTopLabel,
  ]);

  const silentlyPostRefetch = async () => {
    const data = await fetchSinglePost(postId);
    setPost(data);
  };

  return (
    <SidebarWrapper>
      {loading || !post ? (
        <SmallLoader />
      ) : (
        <>
          {post && (
            <PostCard
              post={post}
              isPostPage
              refetchFunc={silentlyPostRefetch}
            />
          )}
        </>
      )}
    </SidebarWrapper>
  );
};

export default SinglePost;
