import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Card,
  Avatar,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Favorite, ChatBubbleOutline, Send } from "@mui/icons-material";
import { AuthContext } from "../../contexts/Auth.context";
import {
  likeComment,
  likeReply,
  replyToComment,
} from "../../utils/functions/posts";
import { LoadingButton } from "@mui/lab";
import { customColors } from "../../assets/styles/Theme";
import { useParams } from "react-router-dom";

const CommentCard = ({ comment, refetchFunc }) => {
  const { postId } = useParams();
  const {
    text,
    timestamp,
    likes,
    replies,
    id: commentId,
    user: commenter,
  } = comment;

  const { userData } = useContext(AuthContext);

  // State for reply text
  const [replyText, setReplyText] = useState("");
  const [showReplies, setShowReplies] = useState(false);
  const [replyLoading, setReplyLoading] = useState(false);
  const [likeLoading, setLikeLoading] = useState(false);
  const [replyLikeLoading, setReplyLikeLoading] = useState(false);

  const handleLikeComment = async () => {
    setLikeLoading(true);
    await likeComment(postId, commentId, userData.uid);
    setLikeLoading(false);
    refetchFunc();
  };

  const handleReplySubmit = async () => {
    if (replyText.trim()) {
      setReplyLoading(true);
      await replyToComment(postId, commentId, userData.uid, replyText.trim());
      setReplyText("");
      setReplyLoading(false);
      refetchFunc();
    }
  };

  const handleLikeReply = async (replyId) => {
    setReplyLikeLoading(true);
    await likeReply(postId, commentId, replyId, userData.uid);
    setReplyLikeLoading(false);
    refetchFunc();
  };

  return (
    <Card sx={{ mb: 2, p: 2, borderRadius: 2, boxShadow: 1 }}>
      {/* Header */}
      <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
        <Avatar
          alt="User Avatar"
          src={commenter?.avatar || ""}
          sx={{ mr: 2 }}
        />
        <Box>
          <Typography variant="subtitle2" fontWeight="bold">
            {commenter?.firstName || "Anonymous"}{" "}
            {commenter?.lastName || "User"}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {new Date(timestamp.seconds * 1000).toLocaleString()}
          </Typography>
        </Box>
      </Box>

      {/* Content */}
      <Typography variant="body2" sx={{ mb: 2 }}>
        {text}
      </Typography>

      {/* Actions */}
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Box>
          <IconButton onClick={handleLikeComment}>
            {likeLoading ? (
              <CircularProgress size={14} sx={{ color: "#cd486b" }} />
            ) : (
              <Favorite
                sx={{
                  color: likes.includes(userData.uid) ? "#cd486b" : "default",
                }}
              />
            )}
          </IconButton>
          <Typography variant="caption">{likes.length} Likes</Typography>
        </Box>

        <Box onClick={() => setShowReplies(!showReplies)}>
          <IconButton>
            <ChatBubbleOutline />
          </IconButton>
          <Typography variant="caption">{replies.length} Replies</Typography>
        </Box>
      </Box>

      {/* Reply Input */}
      {showReplies && (
        <Box sx={{ mt: 2, textAlign: "right" }}>
          <TextField
            fullWidth
            size="small"
            placeholder="Write a reply..."
            value={replyText}
            onChange={(e) => setReplyText(e.target.value)}
            sx={{ mb: 1 }}
          />
          <LoadingButton
            sx={{
              paddingX: "1rem",
              borderRadius: "1rem",
              backgroundColor: customColors.color_primary,
              color: customColors.color_white,
              ":hover": { backgroundColor: customColors.color_primary },
              my: "0.2rem",
            }}
            endIcon={<Send />}
            onClick={handleReplySubmit}
            disabled={!replyText.trim()}
            loading={replyLoading}
          >
            Reply
          </LoadingButton>
        </Box>
      )}

      {/* Replies */}
      {showReplies &&
        replies.map((reply) => (
          <Box key={reply.id} sx={{ pl: 4, mt: 2 }}>
            <Card sx={{ mb: 2, p: 1, borderRadius: 1, boxShadow: 0.5 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Avatar
                  alt="Reply User Avatar"
                  src={reply?.user?.avatar || ""}
                  sx={{ mr: 2 }}
                />
                <Box>
                  <Typography variant="subtitle2" fontWeight="bold">
                    {reply?.user?.firstName || "Anonymous"}{" "}
                    {reply?.user?.lastName || "User"}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {new Date(reply.timestamp.seconds * 1000).toLocaleString()}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="body2">{reply.text}</Typography>

              <Box>
                <IconButton onClick={() => handleLikeReply(reply.id)}>
                  {replyLikeLoading ? (
                    <CircularProgress size={14} sx={{ color: "#cd486b" }} />
                  ) : (
                    <Favorite
                      sx={{
                        color: reply?.likes.includes(userData.uid)
                          ? "#cd486b"
                          : "default",
                      }}
                    />
                  )}
                </IconButton>
                <Typography variant="caption">
                  {reply?.likes?.length} Likes
                </Typography>
              </Box>
            </Card>
          </Box>
        ))}
    </Card>
  );
};

export default CommentCard;
